<template>
  <div>asasdasd</div>
  <!-- <div class="container">
    <div v-for="(item, index) in films" :key="index" class="film-wrapper row"  @click="showMe(index)">
      <div
        v-if="
          `http://image.tmdb.org/t/p/w500${item.poster_path}` !=
            `http://image.tmdb.org/t/p/w500null`
        "
        class="film__preview col-12 col-md-3"
      >
        <img
          :src="`http://image.tmdb.org/t/p/w500${item.poster_path}`"
          width="100%"
          alt="Poster image"
        />
      </div>
      <div v-else class="film__preview col-12 col-md-3">
        <img
          src="../assets/images/1544115393_poster_none.png"
          width="100%"
          alt="Poster image"
        />
      </div>
      <div class="film__data_wrap col-12 col-md-9">
        <div v-if="item.title" class="film__data-inner">
          <span> Название: </span>
          <span> {{ item.title }} </span>
        </div>
        <div v-else class="film__data-inner">
          <span> Название: </span>
          <span> {{ item.original_name }} </span>
        </div>
        <div class="film__data-inner">
          <span> Дата премьеры: </span>
          <span> {{ item.release_date }} </span>
        </div>
        <div class="film__data-inner">
          <span> Описание: </span>
          <span> {{ item.overview | cut() }} </span>
        </div>
      </div>
      <hr />
    </div>
  </div> -->
</template>

<script>
export default {
  props: ["id", "picked"],

  data() {
    return {
      apiKey: "8089a6eb2db7e1328d6cacafa1b57a70",
      filmData: [],
      filmDataCrew: [],
      filmDataImg: [],
      meta: [
        { title: "" },
        { poster: "" },
        { overview: "" },
        { production: "" },
      ],
    };
  },
  mounted() {
    console.log("picked: ", this.picked);
    setTimeout(() => {
      let urlMainData = `https://api.themoviedb.org/3/${this.picked}/${this.id}?api_key=${this.apiKey}`;
      let urlActors = `https://api.themoviedb.org/3/${this.picked}/${this.id}/credits?api_key=${this.apiKey}`;
      let urlImages = `https://api.themoviedb.org/3/${this.picked}/${this.id}/images?api_key=${this.apiKey}`;
      fetch(urlMainData)
        .then((response) => response.json())
        .then((answer) => {
          this.filmData.push(answer);
        });
      fetch(urlActors)
        .then((response) => response.json())
        .then((answer) => {
          for (let subItem in answer.crew) {
            while (this.filmDataCrew.length < 6) {
              this.filmDataCrew.push(answer.crew[subItem].job);
              subItem++
            }
            
            
            }
           
          
          console.log("this.filmDataCrew: ", this.filmDataCrew);

          // answer.crew[0].job
          // answer.crew[0].name

          // this.filmDataCrew.push(answer);
        });
      fetch(urlImages)
        .then((response) => response.json())
        .then((answer) => {
          this.filmDataImg.push(answer);
        });
      console.log("this.filmData: ", this.filmData);
      console.log("this.filmDataCrew: ", this.filmDataCrew);
      console.log("this.filmDataImg: ", this.filmDataImg);
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
